import Cookies from 'universal-cookie';
import { useIsAuthenticated } from "@azure/msal-react";
import './App.css';
import Login from "./components/Login"
import Homepage from './components/Homepage';
import { loginRequest, msalInstance } from './authConfig';
import Modal from 'react-modal';
import { useState } from 'react';
import { ActivityService, SolidarityChallengeService } from "./services/Services";


const AppContent = () => {


  const [privacyPopup, setPrivacyPopup] = useState(false)
  const [privacyPolicyPage, setPrivacyPolicyPage] = useState(false)
  const isSsoAuthenticated = useIsAuthenticated();
  const cookies = new Cookies();
  const solidarityChallengeService = new SolidarityChallengeService();
  let isAuthenticated = false;
  let customModalStyle = {

    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '400px',
      height: '600px',
      marginRight: '-50%',
      padding: '30px',
      transform: 'translate(-50%, -50%)',
      'z-index': 99,
      transition: "opacity 20ms ease-in -out",
      "border-radius": "30px",
      overflow: 'hidden'
    },

  };


  //check if the user is authenticated
  if (cookies.get('user_token') === undefined) {
    if (isSsoAuthenticated) {
      cookies.set('user_token', 'temp', { path: '/', maxAge: 31536000 });
      cookies.set('user_token_type', 'sso', { path: '/', maxAge: 31536000 });
      isAuthenticated = true;
    } else {
      if (window.location.href.includes('team/')) {
        let path = window.location.href.split('team/')
        sessionStorage.setItem('team_id', path[1])


      }
      msalInstance.loginRedirect(loginRequest).catch(e => {
        console.log(e)
      })
    }
  } else {
    isAuthenticated = true;
  }
  solidarityChallengeService.getTranslation().then(() => {
    //localStorage.setItem('privacy_policy', false)
    let path = window.location.href.split('staging/')
    let original_path = path[0] + 'staging/'
    if (window.location.hostname == 'changemakerschallenge.gucci.cn') {
      path = window.location.href.split('.cn/')
    } else if (window.location.hostname == 'changemakerschallenge.gucci.com') {
      path = window.location.href.split('.com/')
    }


    if (path.length > 1 && path[1] === '?privacy-policy') {
      sessionStorage.setItem('privacy_policy', true)
      //sessionStorage.setItem('privacy_policy_entered', false)
    }
  })

  //if (sessionStorage.getItem('privacy_policy_entered') && path.length == 1) {
  //  sessionStorage.setItem('privacy_policy', true)
  //}


  return (
    <>
      <Login isAuthenticated={isAuthenticated} privacyPopup={privacyPopup} setPrivacyPopup={setPrivacyPopup} />
      <Modal
        isOpen={privacyPopup}
        onAfterOpen={null}
        onRequestClose={() => setPrivacyPopup(false)}
        style={customModalStyle}
        className={'modal_base'}
        appElement={document.getElementById('root') || undefined}
      >
        <div className="modal_content_container">
          <div className="modal_header flex">
            <div className="modal_content_title">Info Popup</div>
            <div className="close_button" >
              <svg onClick={() => setPrivacyPopup(false)} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </div>
          <div className="modal_activity_list_container gucci_text mt-3" Style="max-width: 400px; text-align: justify;">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
          </div>

        </div>
      </Modal>
    </>
  );
};
export default function App() {
  return (

    <AppContent />
  );
}
